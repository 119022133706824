import React from "react";
import HeroSection from "../components/HeroSection";
import AboutSection from "../components/AboutSection";
import ExperienceSection from "../components/FeaturesSections/ExperienceSection";
import EssentialsSection from "../components/FeaturesSections/EssentialsSection";
import SocializingSection from "../components/FeaturesSections/SocializingSection";
import GetStartedSection from "../components/GetStartedSection";
import FAQSection from "../components/FAQSection";
import ContactSection from "../components/ContactSection";
import Layout from "../layout";

const HomePage = ({ handleClick, activeTab, tabs }) => {
  return (
    <div className="bg-light">
      <section id="home" className="mb-[50px]">
        <HeroSection
          handleClick={handleClick}
          activeTab={activeTab}
          tabs={tabs}
        />
      </section>

      <section
        id="about"
        className="py-[75px] max-sm:py-[30px] sm:py-[30px] md:py-[75px] mx-auto flex justify-between max-sm:flex-col sm:flex-col xl:flex-row md:flex-col lg:flex-row bg_xx_image xl:max-w-[1180px] lg:max-w-[1180px] lg:w-[97%] md:max-w-[95%] max-sm:max-w-[95%] sm:max-w-[95%]"
      >
        <AboutSection />
      </section>

      <section
        id="services"
        className="py-[75px] max-sm:py-[30px] sm:py-[30px] md:py-[75px] mx-auto xl:max-w-[1180px] lg:max-w-[1180px] lg:w-[97%] md:max-w-[95%] max-sm:max-w-[95%] sm:max-w-[95%] "
      >
        <ExperienceSection />
      </section>

      <section className="py-[75px] max-sm:py-[30px] sm:py-[30px] md:py-[75px] mx-auto xl:max-w-[1180px] lg:max-w-[1180px] lg:w-[97%] md:max-w-[95%] max-sm:max-w-[95%] sm:max-w-[95%] ">
        <EssentialsSection />
      </section>

      <section className="py-[75px] sm:py-[30px] md:py-[75px] max-sm:py-[60px] mx-auto">
        <SocializingSection />
      </section>

      {/* <section className="py-[75px] max-sm:py-[30px] sm:py-[30px] md:py-[75px] mx-auto xl:max-w-[1180px] lg:max-w-[1180px] lg:w-[97%] md:max-w-[95%] max-sm:max-w-[95%] sm:max-w-[95%] ">
        <GetStartedSection />
      </section> */}

      <section
        id="faq"
        className="py-[75px] max-sm:py-[35px] sm:py-[35px] mx-auto xl:max-w-[1180px] lg:max-w-[1180px] lg:w-[97%] md:max-w-[95%] max-sm:max-w-[95%] sm:max-w-[95%] "
      >
        <h3 className="text-12xl mb-7 max-sm:text-5xl text-dark font-lightBold text-center leading-[1px] max-sm:leading-[30px] sm:leading-[30px]">
          Frequently Asked Questions
        </h3>
        <FAQSection />
      </section>

      <section
        id="contact"
        className="mt-[75px] max-sm:mt-[30px] sm:mt-[30px] py-[40px] max-sm:py-[15px] sm:py-[15px] flex justify-center bg-brown1 mx-auto"
      >
        <ContactSection />
      </section>
    </div>
  );
};

export default Layout(HomePage);
