import React from "react";

import large from "../assets/images/larg_img.jpeg";
import small from "../assets/images/small_img.jpeg";

const AboutSection = () => {
  return (
    <>
      <div className="relative max-sm:w-[336px] md:w-[596px] lg:w-[596px] xl:w-[596px] max-sm:hidden ">
        <div
          className="relative max-sm:w-[302px] md:w-[539px] lg:w-[500px] xl:w-[539px] 
             max-sm:h-[216px] md:h-[386px] lg:h-[386px] xl:h-[386px] "
        >
          <img
            src={large}
            alt="Large party"
            className="rounded-lg max-sm:w-[302px] md:w-[539px] lg:w-[500px] xl:w-[539px] 
                max-sm:h-[216px] md:h-[386px] lg:h-[386px] xl:h-[386px] object-cover"
          />
        </div>

        <div className="absolute bottom-[-4px] right-[0%]">
          <img
            src={small}
            alt="Small party"
            className="rounded-lg max-sm:w-[125px] md:w-[227px] lg:w-[227px] xl:w-[227px] 
            max-sm:h-[92px] md:h-[162.43px] lg:h-[162.43px] xl:h-[162.43px] object-cover border-4 border-light"
          />
        </div>
      </div>
      <div className="flex flex-col justify-center lg:p-4 lg:w-[45%] md:w-full max-sm:w-full sm:w-full max-sm:mt-4 ">
        <h3 className="text-12xl text-primary font-lightBold max-sm:text-center">
          About Mixxer
        </h3>
        <p className="text-base max-sm:text-md max-md:text-md text-lightbrown font-normal lg:leading-[30px] sm:leading-[20px] leading-relaxed mt-2 max-sm:text-center ">
          Mixxer is a social app for anyone looking to find friends and plan
          outings quickly, making it simple to meet up with new people or
          reconnect with familiar faces as soon as the same day. With intuitive
          tools for finding, planning, and joining local meetups, Mixxer
          empowers users to seamlessly schedule social experiences, from casual
          coffee catch-ups to spontaneous nights out. Mixxer was created by
          husband-and-wife duo Dallas and Charity Locke. After moving to
          Houston, Charity found it difficult to establish a new social circle,
          a challenge many adults face when trying to make friends. Motivated to
          solve this common struggle, she combined her expertise in marketing
          and analytics with Dallas’s software engineering background to design
          a platform that simplifies and streamlines social connections.
        </p>
      </div>
    </>
  );
};

export default AboutSection;
