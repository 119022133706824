import React from "react";

import appStore from "../assets/images/appstore.png";
import playStore from "../assets/images/playstore.png";
import { HeaderSection } from "./HeaderSection";

const HeroSection = ({ handleClick, activeTab, tabs }) => {
  return (
    <div
      // className="bg_gradient max-sm:bg-cover sm:bg-cover h-[753px] max-sm:h-[536px]"
      className="bg_gradient max-sm:bg-cover sm:bg-cover"
      // style={{ height: "100vh" }}
    >
      <div className="h-full max-w-[1180px] mx-auto">
        <HeaderSection
          handleClick={handleClick}
          activeTab={activeTab}
          tabs={tabs}
        />
        <section
          className="flex justify-center items-center min-h-[calc(100%-153px)] w-[90%] mx-auto banne rPadding pb-12"
          style={{ height: "100vh" }}
        >
          <div className="text-center">
            <h2 className="text-22xl max-sm:text-7xl font-bold text-white mb-4">
              Make friends today, not someday.
            </h2>
            <p className="text-2xl max-sm:text-md text-white mb-6 w-[55%] mx-auto text-center md:w-[70%] max-sm:w-[80%]">
              {/* Download Mixxer to discover people who are ready to meet, explore,
            and hang out—right now. */}
              COMING SOON
            </p>
            <div className="mt-6 w-[280px] max-sm:w-[50] flex justify-center mx-auto space-x-4">
              {/* <img
                src={playStore}
                className="w-[134px] h-[45px]"
                alt="social"
              /> */}
              {/* <img src={appStore} className="w-[134px] h-[45px]" alt="social" /> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default HeroSection;
